/* eslint-env jquery */
/* eslint-disable no-console */
/* eslint indent: [1, 2, {"SwitchCase": 1}]*/

'use strict';

const App = {};
const CommandeManager = {};
const FormManager = {};
const PopinManager = {};

/*
 *  GLOBAL FUNCTIONS
 *  -------------
 *  defined in alphabetical order.
 *
 */

App.debounce = function (func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

/*
 *  INIT INTERFACE MANAGERS
 *  -------------
 *  Methods are defined and called in alphabetical order.
 *
 */

App.init = function () {
  this.initBlockLevelLinks();
  this.initBuyNowButton();
  this.initDuplicateCommande();
  this.initCommandeManager();
  this.initDuplicateCommande();
  this.initFormManager();
  this.initGuestPreview();
  this.initInfiniteEntries();
  this.initNestedCategories();
  this.initPopinManager();
  this.initPopupLaits();
  this.initProduitsPLV();
  this.initProductQuantity();
  this.initToolTips();
};

App.initBlockLevelLinks = function () {
  const $elem = $('[data-href]');
  if ($elem.length) {
    $elem.css({ cursor: 'pointer' });
    $elem.on('click', function () {
      window.location.assign($elem.data('href'));
    });
  }
};

App.initBuyNowButton = function () {
  const $elem = $('.buynow');
  if ($elem.length) {
    $elem.on('click', function (event) {
      event.preventDefault();
      const $this = $(this);
      const $spinner = $this.find('.spinner');
      const $check = $this.find('.fa-check');
      const itemId = $this.data('item_id');
      const itemCount = parseInt($this.parent().find('.item_count').val(), 10) || 1;
      const csrfToken = $this.data('csrf_token');
      const postUrl = '/commande/ajout-produit/';
      const postData = {
        item_id: itemId,
        item_count: itemCount,
        csrf_token: csrfToken,
        is_ajax: 1,
      };
      $spinner.removeClass('d-none');
      $.post(
        postUrl,
        postData,
        function (json) {
          App.updateNavCommandeLink(json.commande_count);
          $spinner.addClass('d-none');
          $check.removeClass('d-none');
          $this.attr('disabled', true);
        },
        'json'
      );
    });
  }
};

App.updateNavCommandeLink = function (commande_count) {
  $('#commandeLink').toggleClass('disabled', !commande_count);
};

App.initCommandeManager = function () {
  CommandeManager.init();
  CommandeManager.onCommandeUpdated = function (commande_count) {
    App.updateNavCommandeLink(commande_count);
  };
};

App.initDuplicateCommande = function () {
  const $el = $('#duplicateCommandeButton');
  if (!$el.length) {
    return;
  }
  $el.on('click', function (event) {
    event.preventDefault();
    $('#duplicateCommandeIcon').removeClass('d-inline-block').addClass('d-none');
    $('#duplicateCommandeSpinner').removeClass('d-none');
    setTimeout(function () {
      window.location.href = $el.attr('href');
    }, 100);
  });
};

App.initFormManager = function () {
  FormManager.init();
};

App.initGuestPreview = function () {
  const $el = $('#guestPreview');
  if (!$el.length) {
    return;
  }
  const $form = $el.find('form');
  const $select = $el.find('select');
  $select.on('change', function () {
    const $group_id = $(this).children('option:selected').val();
    if ($group_id) {
      $form.trigger('submit');
    }
  });
};

App.initInfiniteEntries = function () {
  $('.entries.infinite-scroll').infinitescroll(
    {
      behavior: 'twitter', // use manual trigger link to load more entries
      navSelector: '.pagination', //selector for element wrapping next link
      nextSelector: '.infinite-link', //selector for next link
      itemSelector: '.entry', // selector for list items
      animate: true,
      loading: {
        selector: '.infinite-loading', // selector for loading div wrapper
        speed: 0, // removes delay (sliding effect) on show/hide loader
        finishedMsg: 'Vous avez chargé tous les résultats !', // text to appear when you reach the end of the list
        msgText: 'Chargement...', // text to appear while loading
        img: '/images/global/loading.gif', // image to display while loading
      },
      extractLink: true, // this line is vital for this to work in EE
    },
    function (newElements, data) {
      // callback function, called after each set of items are loaded in
      // it's used to hide the view more link when we are at the end
      // in EE template we put a class of end on the last iftem,
      // so if we find that item, hide the trigger link
      if ($('.entry.last').length) {
        $('.infinite-pagination').hide();
      }
      if (data) {
        // eslint is happy, you have used data :)
        data = null;
      }
    }
  );
  $('.infinite-loading').css({ 'text-align': 'center', width: '100%' });
};

App.initNestedCategories = function () {
  const $elem = $('.nested_categories');
  if ($elem.length) {
    const openList = function ($ul) {
      $ul.removeClass('closed');
      $ul.addClass('open');
      $ul.slideDown('fast');
    };

    const closeList = function ($ul) {
      $ul.removeClass('open');
      $ul.addClass('closed');
      $ul.slideUp('fast');
    };

    const closeAnyOpenSibling = function ($ul) {
      $('.sublist.open')
        .not($ul)
        .each(function () {
          if ($(this).attr('class') === $ul.attr('class')) {
            closeList($(this));
          }
        });
    };

    const handleClick = function ($elem) {
      if ($elem.hasClass('open')) {
        closeList($elem);
      } else {
        openList($elem);
        closeAnyOpenSibling($elem);
      }
    };

    $('.nested_categories > li > ul').addClass('sublist sublist--level1').hide().addClass('closed');
    $('.nested_categories > li > ul > li > ul')
      .addClass('sublist sublist--level2')
      .hide()
      .addClass('closed');
    $('.nested_categories > li > ul > li').addClass('sublist-item--level1');
    $('.nested_categories > li > ul > li > ul > li').addClass('sublist-item--level2');
    $('.nested_categories li:has(ul)').addClass('has-sublist');
    $('.nested_categories ul').hide().addClass('closed');

    $('.has-sublist > a').on('click', function (e) {
      e.preventDefault();
      e.stopPropagation();
      const $target = $(this).parent().find('ul').first();
      handleClick($target);
    });
    $('.startopen').trigger('click');
  }
};

App.initPopinManager = function () {
  PopinManager.init();
};

App.initPopupLaits = function () {
  const $elem = $('#sct_popuplaits');
  if ($elem.length) {
    $elem.modal('show');
  }
};

App.lockScroll = function (bool) {
  $('body').css('overflow', bool ? 'hidden' : 'auto');
};

App.initProductQuantity = function () {
  const $elem = $('.product-quantity input.item_count');
  if ($elem.length) {
    $elem.on('keydown', function (e) {
      if (
        e.key !== 'ArrowRight' &&
        e.key !== 'ArrowLeft' &&
        e.key !== 'Backspace' &&
        e.key !== 'Enter' &&
        (!(
          e.key === '0' ||
          e.key === '1' ||
          e.key === '2' ||
          e.key === '3' ||
          e.key === '4' ||
          e.key === '5' ||
          e.key === '6' ||
          e.key === '7' ||
          e.key === '8' ||
          e.key === '9'
        ) ||
          $(this).val().length > 1)
      ) {
        e.preventDefault();
      }
    });
    $elem.on(
      'change paste',
      App.debounce(function () {
        const $this = $(this);
        const regExp = /^[0-9]\d*$/;
        let newQuantity = $this.val();
        if (regExp.test(newQuantity) && newQuantity > 0) {
          newQuantity = newQuantity < 99 ? newQuantity : 99;
        } else {
          $this.val(1);
        }
      }, 500)
    );
  }
};

App.initProduitsPLV = function () {
  const $elem = $('.produit-plv');
  const $submit = $('#plv-buynow');
  if ($elem.length) {
    $elem.each(function () {
      const $this = $(this);
      const $input = $this.find('input');
      const $check = $this.find('.fa-check');
      $input.on('change', function () {
        const count = parseInt($(this).val());
        if (count > 0) {
          $check.removeClass('d-none');
        } else {
          $check.addClass('d-none');
        }
      });
    });
    $('#plv-buynow').on('click', function (e) {
      e.preventDefault();
      let count = 0;
      $elem.each(function () {
        count += parseInt($(this).find('input').val());
      });
      if (count > 0) {
        $('#plv-form').trigger('submit');
      }
    });
  }
};

App.initToolTips = function () {
  $('[data-toggle="tooltip"]').tooltip();
};

/*
 *  COMMANDE MANAGER
 *  ----------------
 *  Handles all client side events related to purchasing items :
 *  > add/remove items in cart
 *  > calculate totals, shipping costs etc.
 *  Not sure yet if we push to the database...
 *  Methods are defined and called in alphabetical order.
 *
 */

CommandeManager.init = function () {
  this.initQuantitySettings();
  this.initLivraisonSetting();
  this.initCheckout();
};

CommandeManager.initQuantitySettings = function () {
  const $el = $('.quantity-setting');
  if (!$el.length) {
    return;
  }

  const $input = $('.quantity-setting input');
  const $less = $('.quantity-setting .btn-decrement');
  const $more = $('.quantity-setting .btn-increment');

  $input.on('keydown', function (e) {
    if (
      e.key !== 'ArrowRight' &&
      e.key !== 'ArrowLeft' &&
      e.key !== 'Backspace' &&
      e.key !== 'Enter' &&
      e.key !== 'Tab' &&
      (!(
        e.key === '0' ||
        e.key === '1' ||
        e.key === '2' ||
        e.key === '3' ||
        e.key === '4' ||
        e.key === '5' ||
        e.key === '6' ||
        e.key === '7' ||
        e.key === '8' ||
        e.key === '9'
      ) ||
        $(this).val().length > 1)
    ) {
      e.preventDefault();
    }
  });

  $input.on(
    'change paste',
    App.debounce(function () {
      const $this = $(this);
      const quantity = $this.data('count');
      const regExp = /^[0-9]\d*$/;
      let newQuantity = $this.val();
      if (regExp.test(newQuantity)) {
        newQuantity = newQuantity < 99 ? newQuantity : 99;
        CommandeManager.handleNewQuantitySetting($this, newQuantity);
      } else {
        $this.val(quantity);
      }
    }, 1000)
  );

  $less.on('click', function () {
    const $input = $(this).parent().find('input');
    const quantity = parseInt($input.val());
    const newQuantity = quantity - 1;
    if (newQuantity >= 0) {
      CommandeManager.handleNewQuantitySetting($input, newQuantity);
    }
  });

  $more.on('click', function () {
    const $input = $(this).parent().find('input');
    const quantity = parseInt($input.val());
    const newQuantity = quantity + 1;
    if (newQuantity <= 99) {
      CommandeManager.handleNewQuantitySetting($input, newQuantity);
    }
  });
};

CommandeManager.initLivraisonSetting = function () {
  const $el = $('.livraison-setting');
  if (!$el.length) {
    return;
  }
  $('.livraison-setting input').on('change', function () {
    CommandeManager.updateLivraisonSetting(this.value);
  });
};

CommandeManager.updateLivraisonSetting = function (modeLivraison) {
  CommandeManager.modeLivraison = modeLivraison;
  CommandeManager.updateCommandeData();
};

CommandeManager.calculateShipping = function (totalCount, totalProduits) {
  // Note : Les clients grossistes et groupement ne paient pas de frais de livraison.
  //
  // Retrait :: port gratuit
  if (CommandeManager.modeLivraison == 2) {
    return 0;
  }
  // Livraison :: prix du port en fonction du nombre de cartons
  // Gratuit à partir de 20 cartons
  let shipping = 0;
  switch (true) {
    case totalCount <= 5:
      shipping = Math.floor(totalProduits * 0.05 * 100) / 100;
      break;
    case totalCount >= 6 && totalCount <= 10:
      shipping = Math.floor(totalProduits * 0.03 * 100) / 100;
      break;
    case totalCount >= 11 && totalCount <= 15:
      shipping = Math.floor(totalProduits * 0.02 * 100) / 100;
      break;
    case totalCount >= 16 && totalCount <= 19:
      shipping = Math.floor(totalProduits * 0.01 * 100) / 100;
      break;
    case totalCount >= 20:
      shipping = 0;
  }
  return shipping;
};

CommandeManager.currencyFormat = function (aNumber) {
  return new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(aNumber);
};

CommandeManager.handleNewQuantitySetting = function ($input, newQuantity) {
  newQuantity = parseInt(newQuantity) ? newQuantity : 0;
  $input.val(newQuantity);
  if (newQuantity === 0) {
    const id = $input.parent().data('item-id');
    $('.commande-item[data-item-id="' + id + '"]').hide();
  }
  CommandeManager.updateCommandeData();
};

CommandeManager.updateElementText = function ($element, text) {
  $element.text(CommandeManager.currencyFormat(text));
};

CommandeManager.updateCommandeData = function () {
  let totalCount = 0;
  let totalProduits = 0;
  let totalTaxes = 0;
  let totalHorsTaxes = 0;
  let totalShipping = 0;
  let totalCommande = 0;

  // Loop through commande items to calculate new totals
  $('.commande-item').each(function () {
    const $this = $(this);
    const $input = $this.find('input');

    const itemPrice = parseFloat($input.data('price'));
    const itemCount = parseInt($input.val());
    const itemTaxRate = parseFloat($input.data('tax'));
    const itemTotal = itemPrice * itemCount;
    const itemTotalTax = Math.floor(itemTotal * itemTaxRate * 100) / 100;

    // Set text for item total
    let itemTotalPriceText = CommandeManager.currencyFormat(itemTotal);
    $this.find('.itemTotalPrice').text(itemTotalPriceText);

    // Calculate value for totalProduits
    totalTaxes += itemTotalTax;
    totalProduits += itemTotal;
    totalCount += itemCount;
  });

  totalShipping = CommandeManager.calculateShipping(totalCount, totalProduits);
  totalHorsTaxes = totalProduits + totalShipping;
  // Add TVA 8.5% to shipping
  totalTaxes += Math.floor(totalShipping * 0.085 * 100) / 100;
  totalCommande = totalHorsTaxes + totalTaxes;

  // Set text for total produits
  CommandeManager.updateElementText($('#totalProduits'), totalProduits);
  CommandeManager.updateElementText($('#totalShipping'), totalShipping);
  CommandeManager.updateElementText($('#totalTaxes'), totalTaxes);
  CommandeManager.updateElementText($('#totalHorsTaxes'), totalHorsTaxes);
  CommandeManager.updateElementText($('#totalCommande'), totalCommande);

  // Calculate and set shipping alert
  // Free shipping starts at 20 items
  let countToFreeshipping = 20 - totalCount;
  const $freeshippingAlert = $('#freeshippingAlert');
  if (countToFreeshipping > 0) {
    $('#countToFreeshipping').text(countToFreeshipping);
    $freeshippingAlert.removeClass('freeshipping-yes');
    $freeshippingAlert.addClass('freeshipping-no');
  } else {
    $freeshippingAlert.removeClass('freeshipping-no');
    $freeshippingAlert.addClass('freeshipping-yes');
  }

  CommandeManager.checkMinimumCount();
  CommandeManager.postUpdate();
};

CommandeManager.checkMinimumCount = function () {
  // If client is grossiste, we have #minimumCountAlert in the dom
  const $minimumCountAlert = $('#minimumCountAlert');
  if ($minimumCountAlert.length) {
    const $checkout = $('#checkout');
    const minimumCount = $minimumCountAlert.data('limit');
    // Loop through commande items to calculate totalCount
    let totalCount = 0;
    $('.commande-item').each(function () {
      totalCount += parseInt($(this).find('input').val());
    });
    let countToMinimum = minimumCount - totalCount;
    if (countToMinimum > 0) {
      $('#countToMinimum').text(countToMinimum);
      $minimumCountAlert.removeClass('d-none');
      $minimumCountAlert.addClass('d-block');
      $checkout.addClass('minimumBlocked');
      $checkout.addClass('disabled');
    } else {
      $minimumCountAlert.removeClass('d-block');
      $minimumCountAlert.addClass('d-none');
      $checkout.removeClass('minimumBlocked');
      $checkout.removeClass('disabled');
    }
  }
};

CommandeManager.postUpdate = App.debounce(function () {
  // Init variables
  let updateArray = [];
  const csrfToken = $('#commande').data('csrf_token');
  const postUrl = '/commande/update/';

  // Get data from DOM (erm... gross.)
  $('.commande-item').each(function () {
    const $this = $(this);
    const id = $this.data('item-id');
    const count = parseInt($this.find('input').val(), 10);
    const item = {
      item_id: id,
      item_count: count >= 0 ? count : 0,
    };
    updateArray.push(item);
  });

  var postData = {
    csrf_token: csrfToken,
    mode_livraison: CommandeManager.modeLivraison,
    update_array: updateArray,
  };

  CommandeManager.disableCheckout(true);

  $.post(
    postUrl,
    postData,
    function (json) {
      CommandeManager.onCommandeUpdated(json.commande_count);
    },
    'json'
  )
    .done(function (json) {
      if (parseInt(json.commande_count) === 0) {
        location.reload();
      } else {
        CommandeManager.disableCheckout(false);
      }
    })
    .fail(function () {
      location.reload();
    });
}, 1000);

CommandeManager.disableCheckout = function (bool) {
  const $checkout = $('#checkout');
  $checkout.attr('disabled', bool);
  $checkout.toggleClass('disabled', bool);
  CommandeManager.checkMinimumCount();
};

CommandeManager.initCheckout = function () {
  CommandeManager.checkMinimumCount();
  $('#checkout').on('click', function (e) {
    e.preventDefault();
    const $this = $(this);
    if (!$this.hasClass('disabled') && !$this.hasClass('minimumBlocked')) {
      $this.attr('disabled', true);
      $this.toggleClass('disabled', true);
      $this.find('.spinner').removeClass('d-none');
      $('#checkoutForm').trigger('submit');
    }
  });
};

/*
 *  FORM MANAGER
 *  -------------
 *  Handles form validation. Enables and disables form submission.
 *  Methods are defined and called in alphabetical order.
 *
 */

FormManager.init = function () {
  const $registerForm = $('#registerForm');
  if ($registerForm.length) {
    this.initRegisterForm($registerForm);
  }
  const $changePasswordForm = $('#changePasswordForm');
  if ($changePasswordForm.length) {
    this.initChangePasswordForm($changePasswordForm);
  }
  const $newShippingPointForm = $('#newShippingPointForm');
  if ($newShippingPointForm.length) {
    this.initNewShippingPointForm($newShippingPointForm);
  }
};

FormManager.initChangePasswordForm = function ($elem) {
  const $password = $elem.find('#password');
  const $password_confirm = $elem.find('#password_confirm');

  $password.on('input blur', function () {
    FormManager.checkPasswordStrength($password);
  });

  $password_confirm.on('input blur', function () {
    FormManager.checkPasswordMatch($password, $password_confirm);
  });

  $elem.on('submit', function (event) {
    if (
      FormManager.checkPasswordStrength($password) &&
      FormManager.checkPasswordMatch($password, $password_confirm)
    ) {
      return;
    }
    event.preventDefault();
  });
};

FormManager.initNewShippingPointForm = function ($elem) {
  $elem.on('submit', function (event) {
    if (!FormManager.checkNotEmpty($('#libelle'))) {
      $('#libelle').val('Adresse secondaire');
    }
    if (
      FormManager.checkNotEmpty($('#adresse')) &&
      FormManager.checkReunionPostcode($('#postcode')) &&
      FormManager.checkNotEmpty($('#ville'))
    ) {
      return;
    }
    $('#newShippingFormErrorFeedback').removeClass('d-none');
    event.preventDefault();
  });
};

FormManager.initRegisterForm = function ($elem) {
  let currentStep = 0;
  const firstStep = 1;
  const lastStep = 4;

  const $prev = $('#formPrevStep');
  const $next = $('#formNextStep');
  const $stepText = $('#formCurrentStepText');
  const $captcha = $('#formCaptcha');

  // Utilitary functions
  const showPrevStep = function () {
    if (currentStep > firstStep) {
      $('#formStep' + currentStep).hide();
      currentStep--;
      $('#formStep' + currentStep).show();
      updateInterface();
    }
  };
  const showNextStep = function () {
    if (currentStep < lastStep) {
      $('#formStep' + currentStep).hide();
      currentStep++;
      $('#formStep' + currentStep).show();
      FormManager.onRegisterFormStepChange(currentStep);
      updateInterface();
    }
  };
  const updateInterface = function () {
    $stepText.text(currentStep.toString());
    currentStep === firstStep ? $prev.hide() : $prev.show();
    currentStep === lastStep
      ? $next.text('Enregistrer et terminer')
      : $next.text('Enregistrer et continuer');
    currentStep === lastStep ? $captcha.show() : $captcha.hide();
  };

  // Handle click events
  const onClickPrev = function () {
    showPrevStep();
  };
  const onClickNext = function () {
    if (FormManager.validateRegisterFormStep(currentStep)) {
      currentStep === lastStep ? $elem.submit() : showNextStep();
    }
  };

  // Events
  $prev.on('click', function (e) {
    e.preventDefault();
    onClickPrev();
  });
  $next.on('click', function (e) {
    e.preventDefault();
    onClickNext();
  });

  $('#password').on('input blur', function () {
    FormManager.checkPasswordStrength($(this));
  });

  $('#password_confirm').on('input blur', function () {
    FormManager.checkPasswordMatch($('#password'), $('#password_confirm'));
  });

  $('.formStep').hide();
  showNextStep();
};

FormManager.validateRegisterFormStep = function (currentStep) {
  return this['validateRegisterFormStep' + currentStep]();
};

FormManager.onRegisterFormStepChange = function (currentStep) {
  if (currentStep == 4) {
    $('#registerFormModal').modal();
    $('#modalConfirm').on('click', function () {
      FormManager.useFacturationAsLivraison();
      $('#registerFormModal').modal('hide');
    });
  }
};

FormManager.useFacturationAsLivraison = function () {
  const facturationFieldsIds = ['#adresse', '#postcode', '#ville', '#telephone', '#mobile'];
  const livraisonFieldsIds = [
    '#livraison_adresse',
    '#livraison_postcode',
    '#livraison_ville',
    '#livraison_telephone',
    '#livraison_mobile',
  ];
  for (let i = 0; i < livraisonFieldsIds.length; i++) {
    $(livraisonFieldsIds[i]).val($(facturationFieldsIds[i]).val());
  }
};

//  ----------------------------
//  Registration Form :: Etape 1
//  ----------------------------

FormManager.validateRegisterFormStep1 = function () {
  let validation = true;

  if (!this.checkNotEmpty($('#societe'))) {
    validation = false;
  }

  if (!this.checkNotEmpty($('#ape'))) {
    validation = false;
  }

  if (!this.checkSiret($('#siret'))) {
    validation = false;
  }

  return validation;
};

//  ----------------------------
//  Registration Form :: Etape 2
//  ----------------------------

FormManager.validateRegisterFormStep2 = function () {
  let validation = true;

  if (!this.checkNotEmpty($('#nom'))) {
    validation = false;
  }

  if (!this.checkNotEmpty($('#prenom'))) {
    validation = false;
  }

  if (!this.checkEmail($('#email'))) {
    validation = false;
  }

  if (!this.checkPasswordMatch($('#password'), $('#password_confirm'))) {
    validation = false;
  }

  return validation;
};

//  ----------------------------
//  Registration Form :: Etape 3
//  ----------------------------

FormManager.validateRegisterFormStep3 = function () {
  let validation = true;

  if (!this.checkNotEmpty($('#adresse'))) {
    validation = false;
  }

  if (!this.checkReunionPostcode($('#postcode'))) {
    validation = false;
  }

  if (!this.checkNotEmpty($('#ville'))) {
    validation = false;
  }

  if (!this.checkAtLeastOnePhoneNumber($('#telephone'), $('#mobile'))) {
    validation = false;
  }

  return validation;
};

//  ----------------------------
//  Registration Form :: Etape 4
//  ----------------------------

FormManager.validateRegisterFormStep4 = function () {
  let validation = true;

  if (!this.checkNotEmpty($('#livraison_adresse'))) {
    validation = false;
  }

  if (!this.checkReunionPostcode($('#livraison_postcode'))) {
    validation = false;
  }

  if (!this.checkNotEmpty($('#livraison_ville'))) {
    validation = false;
  }

  if (!this.checkAtLeastOnePhoneNumber($('#livraison_telephone'), $('#livraison_mobile'))) {
    validation = false;
  }

  return validation;
};

FormManager.checkPasswordMatch = function ($password, $confirm) {
  let validation = $confirm.val() === $password.val();

  $confirm.toggleClass('is-valid', validation);
  $confirm.parent().find('.invalid-feedback').toggle(!validation);

  return validation;
};

FormManager.checkPasswordStrength = function ($elem) {
  let validation = true;
  const password = $elem.val();
  const feedbackLowercase = $elem.parent().find('#feedbackLowercase');
  const feedbackLength = $elem.parent().find('#feedbackLength');
  const feedbackNumber = $elem.parent().find('#feedbackNumber');
  const feedbackUppercase = $elem.parent().find('#feedbackUppercase');
  const feedbackSymbol = $elem.parent().find('#feedbackSymbol');

  const requirements = {
    lowercase: true,
    length: 10,
    number: true,
    uppercase: true,
    symbol: true,
  };

  // Validate lowercase
  if (!password.match(/[a-z]/)) {
    feedbackLowercase.removeClass('text-success');
    validation = false;
  } else {
    feedbackLowercase.addClass('text-success');
  }
  // Validate minimum length
  if (password.length < requirements.length) {
    feedbackLength.removeClass('text-success');
    validation = false;
  } else {
    feedbackLength.addClass('text-success');
  }
  // Validate number
  if (!password.match(/\d/)) {
    feedbackNumber.removeClass('text-success');
    validation = false;
  } else {
    feedbackNumber.addClass('text-success');
  }
  // Validate uppercase
  if (!password.match(/[A-Z]/)) {
    feedbackUppercase.removeClass('text-success');
    validation = false;
  } else {
    feedbackUppercase.addClass('text-success');
  }
  // Validate symbol
  if (!password.match(/\W/)) {
    feedbackSymbol.removeClass('text-success');
    validation = false;
  } else {
    feedbackSymbol.addClass('text-success');
  }

  $elem.toggleClass('is-valid', validation);
  $elem.toggleClass('is-invalid', !validation);

  return validation;
};

FormManager.checkSiret = function ($field) {
  let digits = $field.val().replace(/\D/g, '');
  let validation = digits.length === 14;
  $field.parent().find('.invalid-feedback').toggle(!validation);
  return validation;
};

FormManager.checkAtLeastOnePhoneNumber = function ($field1, $field2) {
  let value;
  let phone1 = false;
  let phone2 = false;

  if ($field1.val()) {
    value = $field1.val().replace(/\D/g, '');
    phone1 = value.length >= 10;
  }
  if ($field2.val()) {
    value = $field2.val().replace(/\D/g, '');
    phone2 = value.length >= 10;
  }

  return phone1 || phone2;
};

FormManager.checkNotEmpty = function ($field) {
  let validation = $field.val() !== '';
  $field.parent().find('.invalid-feedback').toggle(!validation);
  return validation;
};

FormManager.checkEmail = function ($field) {
  let reg = /\S+@\S+\.\S+/;
  let validation = reg.test($field.val());
  $field.parent().find('.invalid-feedback').toggle(!validation);
  return validation;
};

FormManager.checkReunionPostcode = function ($field) {
  let digits = $field.val().replace(/\D/g, '');
  let validation = digits.length === 5 && digits >= 97400;
  $field.parent().find('.invalid-feedback').toggle(!validation);
  return validation;
};

/*
 *  POPIN MANAGER
 *  -------------
 *  Handles auto show of targetElement (#PopinModal) when the
 *  triggerElement (#PopinModalTrigger) enters in viewport.
 *  Designed to prevent usage with multiple popins on same page.
 *
 */

PopinManager.init = function () {
  const $popin = $('#PopinModal');
  const $trigger = $('#PopinModalTrigger');
  if ($popin.length && $trigger.length) {
    this.targetElement = $popin;
    this.triggerElement = $trigger;
    this.initPopin();
  }
};

PopinManager.initPopin = function () {
  this.popinHasBeenShown = false;

  $(document.body).on('touchmove', PopinManager.onScroll);
  $(window).scroll(function () {
    PopinManager.onScroll();
  });
};

PopinManager.isScrolledIntoView = function ($elem) {
  if ($elem.length) {
    return $(window).scrollTop() >= $elem.offset().top - window.innerHeight;
  }
};

PopinManager.onScroll = function () {
  if (!PopinManager.popinHasBeenShown) {
    if (PopinManager.isScrolledIntoView(PopinManager.triggerElement)) {
      PopinManager.showPopin(PopinManager.targetElement);
    }
  }
};

PopinManager.showPopin = function ($elem) {
  $elem.modal('show');
  $elem.click(function () {
    $(this).modal('hide');
  });
  $('#myModal').on('hide.bs.modal', function () {
    PopinManager.onScroll = null;
  });
  PopinManager.popinHasBeenShown = true;
};

/*
 *  DOM IS READY
 *  -------------
 *  If javascript is available we're good to go.
 *
 */

jQuery(function () {
  document.querySelector('body').classList.remove('no-js');
  App.init();
});
